define("discourse/plugins/discourse-sakura-whispers/discourse/sakura-whispers-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("sakura-whispers", function () {
      this.route("index", {
        path: "/"
      });
      this.route("leaderboard");
    });
  }
});