define("discourse/plugins/discourse-sakura-whispers/discourse/components/sakura-whispers-navigation", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{bodyClass "sakura-whispers"}}
  <ul class="nav-pills">
    <li><LinkTo @route="sakura-whispers.index">🌸 Sakura Whispers</LinkTo></li>
    <li><LinkTo @route="sakura-whispers.leaderboard">📊 Leaderboard</LinkTo></li>
  </ul>
  */
  {
    "id": "K9SSOrth",
    "block": "[[[1,[28,[35,0],[\"sakura-whispers\"],null]],[1,\"\\n\"],[10,\"ul\"],[14,0,\"nav-pills\"],[12],[1,\"\\n  \"],[10,\"li\"],[12],[8,[39,1],null,[[\"@route\"],[\"sakura-whispers.index\"]],[[\"default\"],[[[[1,\"🌸 Sakura Whispers\"]],[]]]]],[13],[1,\"\\n  \"],[10,\"li\"],[12],[8,[39,1],null,[[\"@route\"],[\"sakura-whispers.leaderboard\"]],[[\"default\"],[[[[1,\"📊 Leaderboard\"]],[]]]]],[13],[1,\"\\n\"],[13]],[],false,[\"bodyClass\",\"link-to\"]]",
    "moduleName": "discourse/plugins/discourse-sakura-whispers/discourse/components/sakura-whispers-navigation.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "sakura-whispers-navigation"));
});